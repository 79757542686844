class Nav {
    constructor() {
        this.hamburgerState = false; //false == closed and true == open
        this.main = () => {
            var _a, _b;
            this.hamburger.addEventListener('click', () => {
                this.hamburgerMenu.classList.toggle('open');
                this.animateHamburger(!this.hamburgerState);
            });
            Array.from(this.logos).forEach(logo => logo.addEventListener('click', () => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }));
            this.navLinks.forEach(link => link.addEventListener('click', () => {
                this.hamburgerMenu.classList.remove('open');
                this.animateHamburger(false);
            }));
            this.addNavListenerScroll("#NavContainer h3[dest='Leistungen']", '#Leistungen');
            this.addNavListenerScroll("#NavContainer h3[dest='Ueber']", '#BrandIdentity');
            this.addNavListenerScroll("#NavContainer h3[dest='Vorteil']", '#Nutzen');
            this.addNavListenerScroll("#NavContainer h3[dest='Referenzen']", '#ReferenzenContainer');
            this.addNavListenerScroll("#NavContainer h3[dest='Kontakt']", '#Kontakt');
            this.addNavListenerScroll("nav.desktop > div > h3[dest='Leistungen']", '#Leistungen', 120);
            this.addNavListenerScroll("nav.desktop > div > h3[dest='Ueber']", '#BrandIdentity');
            this.addNavListenerScroll("nav.desktop > div > h3[dest='Referenzen']", '#ReferenzenContainer');
            this.addNavListenerScroll("nav.desktop > div > h3[dest='Kontakt']", '#Kontakt');
            (_a = document.querySelector("#NavContainer h3[dest='Impressum']")) === null || _a === void 0 ? void 0 : _a.addEventListener('click', () => {
                location.href = "/Impressum";
            });
            (_b = document.querySelector("#NavContainer h3[dest='Datenschutz']")) === null || _b === void 0 ? void 0 : _b.addEventListener('click', () => {
                location.href = "/Datenschutz";
            });
            Array.from(document.querySelectorAll('.kontaktBtn')).forEach(btn => btn.addEventListener('click', () => {
                window.scrollTo({
                    left: 0,
                    top: document.querySelector('#Kontakt').offsetTop - 80,
                    behavior: 'smooth'
                });
            }));
            this.desktopHeaderAnimation();
        };
        this.addNavListenerScroll = (query, sectionQuery, additionalOffset = 80) => {
            var _a;
            (_a = document.querySelector(query)) === null || _a === void 0 ? void 0 : _a.addEventListener('click', () => {
                window.scrollTo({
                    left: 0,
                    top: document.querySelector(sectionQuery).getBoundingClientRect().y + window.scrollY - additionalOffset,
                    behavior: 'smooth'
                });
            });
        };
        this.hamburger = document.getElementById('HamburgerContainer');
        this.hamburgerMenu = document.getElementById('NavContainer');
        this.navLinks = document.querySelectorAll('#NavContainer h3');
        this.logos = document.querySelectorAll('.navLogos');
        this.navElements = Array.from(document.querySelectorAll('nav.desktop, nav.desktop > *'));
        this.navImage = document.querySelector('nav.desktop > img');
        this.main();
    }
    animateHamburger(targetState) {
        this.hamburger.children[0].animate([
            { transform: 'translate(0, 0) rotate(0)', offset: 0 },
            { transform: 'translate(0, 9px) rotate(135deg)', offset: 1 }
        ], {
            duration: 400,
            direction: targetState ? "normal" : "reverse",
            fill: "forwards"
        });
        this.hamburger.children[1].animate([
            { transform: 'translate(0, 0) rotate(0)', offset: 0 },
            { transform: 'translate(-7.2px, -3.3px) rotate(-135deg)', offset: 1 }
        ], {
            duration: 400,
            direction: targetState ? "normal" : "reverse",
            fill: "forwards"
        });
        this.hamburger.children[2].animate([
            { transform: 'translate(0, 0) rotate(0)', offset: 0 },
            { transform: 'translate(-2.5px, -3.8px) rotate(225deg)', offset: 1 }
        ], {
            duration: 400,
            direction: targetState ? "normal" : "reverse",
            fill: "forwards"
        });
        this.hamburgerState = targetState;
    }
    desktopHeaderAnimation() {
        let prevScroll = 0;
        this.navImage.insertAdjacentHTML('beforebegin', `<style> 
            nav.desktop > img.small { 
                transform: scale(${38 / this.navImage.getBoundingClientRect().height}) translateY(-60px); 
            }
            nav.desktop {
                
            }
        </style>`);
        window.addEventListener('scroll', e => {
            requestAnimationFrame(() => {
                if (window.scrollY > prevScroll) {
                    this.navElements.forEach(e => e.classList.add("small"));
                }
                else {
                    this.navElements.forEach(e => e.classList.remove('small'));
                }
                prevScroll = window.scrollY;
            });
        });
    }
}
export default Nav;
