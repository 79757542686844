import Scroller from "../Scroller.js";
export default class Referenzen {
    constructor() {
        const referenzenContainer = document.getElementById('ReferenzenContainer');
        this.scroller = new Scroller(referenzenContainer, {
            autoFlow: 0,
            controls: true,
            indicator: false,
            scrollSnap: false,
            type: "Slider"
        });
    }
}
