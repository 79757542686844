import Leistungen from './Leistungen/leistungen.js';
import Identity from './Identity/identity.js';
import Referenzen from './Referenzen/referenzen.js';
import Nav from './nav/nav.js';
function main() {
    var _a;
    new Nav();
    new Leistungen();
    new Identity();
    new Referenzen();
    (_a = document.getElementById('HomeBtnInKontakt')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', () => scrollTo({ left: 0, top: 0, behavior: 'smooth' }));
}
function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        // @ts-ignore
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
// window.addEventListener('resize', debounce(() => {
//     location.reload();
// }, 200));
main();
