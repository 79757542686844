class Identity {
    constructor() {
        this.addStaggerEntry = () => {
            let section = document.getElementById('BrandIdentity');
            const handler = () => {
                requestAnimationFrame(() => {
                    if (section.getBoundingClientRect().top < window.innerHeight - 100) {
                        Array.from(document.querySelectorAll('.identityElement')).forEach(element => {
                            element.classList.add('visible');
                        });
                        window.removeEventListener('scroll', handler);
                    }
                });
            };
            handler();
            window.addEventListener('scroll', handler);
        };
        this.toggleDetails = (element) => {
            element.classList.toggle('open');
            if (element.classList.contains('open')) {
                // @ts-ignore
                element.children[1].children[1].innerHTML = texts[element.children[1].children[0].innerText + "_long"];
                // @ts-ignore
                element.children[1].children[1].style.height = element.children[1].children[2].offsetHeight + "px";
            }
            else {
                // @ts-ignore
                element.children[1].children[1].style.height = texts[element.children[1].children[0].innerText + "_size"] + "px";
                setTimeout(() => {
                    // @ts-ignore
                    element.children[1].children[1].innerHTML = texts[element.children[1].children[0].innerText];
                }, parseFloat(getComputedStyle(element.children[1].children[1]).transitionDuration) * 1000);
            }
        };
        this.closeDetailsBtn = document.querySelectorAll('.detailsClose');
        this.addStaggerEntry();
        Array.from(document.querySelectorAll('.identityElement')).forEach(element => {
            var _a;
            if (window.innerWidth < 650) {
                element.addEventListener('click', () => this.toggleDetails(element));
                const p = element.children[1].children[1];
                p.style.height = p.offsetHeight + "px";
                // @ts-ignore
                texts[((_a = p.parentElement) === null || _a === void 0 ? void 0 : _a.children[0].innerText) + "_size"] = p.offsetHeight;
            }
        });
    }
}
let texts = {
    "Individuell": `Sie sind von Ihrem Konzept überzeugt? Wir auch!<span class="identityMehr"> mehr...</span>`,
    "Kundennah": `Wir stellen uns gezielt auf Sie ein!<span class="identityMehr"> mehr...</span>`,
    "Facettenreich": `Ihr Design ist unser Ding! Und das bei all Ihren Anforderungen.<span class="identityMehr"> mehr...</span>`,
    "Individuell_long": "Sie sind von Ihrem Konzept überzeugt? Wir auch!<br>Deshalb stehen Sie und Ihre Ideen bei uns im Mittelpunkt. Vom Entwurf bis zum fertigen Design arbeiten wir zusammen daran, Ihre Vorstellungen genau zu definieren und schließlich umzusetzen. Dabei behalten wir stets sowohl Ihre Vorgaben als auch das Marktumfeld und die relevanten Zielgruppen im Blick, um Ihr Projekt ins beste Licht zu rücken.",
    "Kundennah_long": "Ob mittelständisches Unternehmen, Selbstständige oder Privatperson: Wir stellen uns gezielt auf Sie ein.<br>Dank regelmäßigem Austausch stellen wir dabei sicher, dass Ihr Projekt jederzeit in die richtige Richtung geht. Auch wenn sich die spezifische Zielsetzung oder einzelne Aspekte im Laufe der Entwicklung verändern sollten, können wir flexibel reagieren und Ihnen ein repräsentatives Ergebnis liefern.",
    "Facettenreich_long": "Ihr Design ist unser Ding! Ob ideenreicher Briefkopf, Neugestaltung Ihrer Webseite oder bildstarke Bannerwerbung / beeindruckendes Corporate Design, jede Thematik ist uns willkommen.<br><br>Gerne realisieren wir universelle Inhalte mit zeitlosem Charakter, ebenso wie trendige Artworks, die selbst Ihre kühnsten Vorstellungen übertreffen.<br>Sie haben bereits einen bestimmten Stil im Sinn? Oder Sie möchten sich gerne persönlich beraten lassen? Wir freuen uns auf Ihre Nachricht / Ihren Anruf."
};
export default Identity;
