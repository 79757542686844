class Scroller {
    constructor(container, options = { scrollSnap: true, autoFlow: 0, controls: true, indicator: true, type: "Carousel" }) {
        this.container = container;
        this.childCount = container.childElementCount;
        this.uuid = this.pseudoUUID();
        this.insertHTML(options);
        this.addFunctionality(options);
    }
    addFunctionality(options) {
        var _a;
        const firstElement = document.querySelector(`#ScrollContainer.${this.uuid} > div`);
        const elementWidth = firstElement.offsetWidth + parseFloat(window.getComputedStyle(firstElement).marginLeft) + parseFloat(window.getComputedStyle(firstElement).marginLeft);
        const buttonLeft = document.querySelector(`#ScrollContainer.${this.uuid} > img#__ScrollLeft`);
        const buttonRight = document.querySelector(`#ScrollContainer.${this.uuid} > img#__ScrollRight`);
        const scrollContainer = this.container.firstChild;
        let scrollPos = 0;
        const scrollCorrection = debounce(() => {
            scrollPos = Math.round(scrollContainer.scrollLeft / elementWidth);
            if (scrollPos >= this.childCount * 3 || scrollPos < this.childCount * 2) {
                scrollContainer.scrollTo({
                    left: elementWidth * (scrollPos % this.childCount) + this.childCount * elementWidth * 2
                });
            }
            if (options.indicator) {
                this.setIndicator(scrollPos % this.childCount);
            }
        });
        const arrowHider = debounce(() => {
            if (scrollContainer.scrollLeft + window.innerWidth > this.childCount * elementWidth - 10) {
                buttonRight.classList.add('__hideControls');
            }
            else {
                buttonRight.classList.remove('__hideControls');
            }
            if (scrollContainer.scrollLeft < 10) {
                buttonLeft.classList.add('__hideControls');
            }
            else {
                buttonLeft.classList.remove('__hideControls');
            }
            scrollPos = Math.round(scrollContainer.scrollLeft / elementWidth);
            if (options.indicator) {
                this.setIndicator(scrollPos);
            }
        }, 50);
        if (options.type === "Carousel") {
            scrollContainer.scrollTo({
                left: elementWidth * this.childCount * 2
            });
            scrollContainer.addEventListener('scroll', scrollCorrection);
        }
        else if (options.type === "Slider") {
            arrowHider();
            scrollContainer.addEventListener('scroll', arrowHider);
        }
        if (options.indicator) {
            this.setIndicator(scrollPos);
        }
        const scrollLeft = () => {
            scrollContainer.scrollBy({
                left: -elementWidth,
                behavior: "smooth"
            });
        };
        buttonLeft.addEventListener('click', scrollLeft);
        const scrollRight = () => {
            scrollContainer.scrollBy({
                left: elementWidth,
                behavior: "smooth"
            });
        };
        buttonRight.addEventListener('click', scrollRight);
        this.adaptToScreenSize(options, this.childCount * elementWidth);
        window.addEventListener('resize', debounce(() => {
            this.adaptToScreenSize(options, this.childCount * elementWidth);
            scrollContainer.removeEventListener('scroll', scrollCorrection);
            scrollContainer.removeEventListener('scroll', arrowHider);
            buttonLeft.removeEventListener('click', scrollLeft);
            buttonRight.removeEventListener('click', scrollRight);
            this.addFunctionality(options);
        }, 60), { once: true });
        if (options.autoFlow != 0) {
            let autoScrollInterval;
            const startInterval = () => {
                autoScrollInterval = setInterval(() => {
                    scrollRight();
                }, 5000);
            };
            startInterval();
            (_a = this.container.firstChild) === null || _a === void 0 ? void 0 : _a.addEventListener("scroll", debounce(() => {
                console.log("Test");
                clearInterval(autoScrollInterval);
                startInterval();
            }));
        }
    }
    adaptToScreenSize(options, containerWidth) {
        if (options.type === "Slider") {
            if (window.innerWidth > containerWidth) {
                document.querySelectorAll(`#ScrollContainer.${this.uuid} > img, #ScrollContainer.${this.uuid} > #Indicator`).forEach(e => e.style.display = "none");
                document.querySelector(`#ScrollContainer.${this.uuid}`).style.justifyContent = "center";
            }
            else {
                document.querySelectorAll(`#ScrollContainer.${this.uuid} > img, #ScrollContainer.${this.uuid} > #Indicator`).forEach(e => e.style.display = "flex");
                document.querySelector(`#ScrollContainer.${this.uuid}`).style.justifyContent = "start";
            }
        }
    }
    setIndicator(pos) {
        const indicatorArray = Array.from(document.querySelectorAll(`#ScrollContainer.${this.uuid} > #Indicator > *`));
        indicatorArray.forEach(e => e.classList.remove('active'));
        indicatorArray[pos].classList.add('active');
    }
    pseudoUUID() {
        return `__${Math.floor(Math.random() * 100000000000)}`;
    }
    //TODO Identify last child for arrow hiding
    insertHTML(options) {
        if (options.type === "Carousel")
            this.container.innerHTML = this.container.innerHTML.repeat(5);
        // @ts-ignore
        if (options.controls)
            this.container.innerHTML = `<img id="__ScrollLeft" src="${new URL("../Images/Arrow_left.svg", import.meta.url)}" alt="Scroll nach links"/> ${this.container.innerHTML} <img id="__ScrollRight" src="${new URL("../Images/Arrow_right.svg", import.meta.url)}" alt="Scroll nach rechts"/>`;
        if (options.controls)
            this.container.innerHTML += this.makeIndicatorHTML(this.childCount);
        this.container.innerHTML += this.styleScrollContainer(options);
        this.container.innerHTML = `<div id="ScrollContainer" class="${this.uuid}">${this.container.innerHTML}</div>`;
    }
    makeIndicatorHTML(childCount) {
        let html = `<div id="Indicator">`;
        for (let i = 0; i < childCount; i++) {
            html += "<div></div>";
        }
        html += "</div>";
        return html;
    }
    styleScrollContainer(options) {
        this.container.style.position = "relative";
        let containerStyle = `
            #ScrollContainer.${this.uuid}::-webkit-scrollbar {
                display: none;
            }

            #ScrollContainer.${this.uuid} {
                -ms-overflow-style: none; 
                ${options.scrollSnap ? 'scroll-snap-type: x proximity;' : ''}
                scrollbar-width: none; 
                overflow-x: scroll !important;
                display: flex;
                width: 100%;
                padding: 0 0 40px 0;
                scroll-snap-type: x mandatory;
            }

            #ScrollContainer.${this.uuid} > div {
                flex-shrink: 0;
                ${options.scrollSnap ? 'scroll-snap-align: center;' : ''}
            }

            ${options.controls ?
            `#ScrollContainer.${this.uuid} > img#__ScrollLeft, #ScrollContainer.${this.uuid} > img#__ScrollRight {
                    display: block;
                    position: absolute;
                    width: 15px;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    z-index: 2;
                }

                #ScrollContainer.${this.uuid} > img.__hideControls.__hideControls {
                    display: none !important;
                }

                #ScrollContainer.${this.uuid} > img#__ScrollLeft { left: 25px; } 

                #ScrollContainer.${this.uuid} > img#__ScrollRight { right: 25px; }`
            : ''}

            
            ${options.indicator ?
            `#ScrollContainer.${this.uuid} > #Indicator {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    gap: 25px;
                }
                
                #ScrollContainer.${this.uuid} > #Indicator > div {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, .6);
                }

                #ScrollContainer.${this.uuid} > #Indicator > div.active {
                    background: rgba(255, 255, 255, .95);
                }`
            : ''}
        `;
        return "<style>" + containerStyle + "</style>";
    }
}
function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        // @ts-ignore
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
export default Scroller;
