import Scroller from "../Scroller.js";
// class Leistungen {
//     scrollerHandler: ScrollHandler;
//     constructor() {
//         const leistungenContainer = document.getElementById('LeistungenContainer') as HTMLElement;
//         this.scrollerHandler = new ScrollHandler(leistungenContainer)
//     }
// }
class Leistungen {
    constructor() {
        const leistungenContainer = document.getElementById('LeistungenContainer');
        this.scroller = new Scroller(leistungenContainer, {
            autoFlow: 6,
            controls: true,
            indicator: true,
            scrollSnap: true,
            type: "Carousel"
        });
    }
}
export default Leistungen;
